/*js时间格式化处理开始*/
Date.prototype.Format = function (fmt) {
    let value = fmt;
    try {
        var o = {
            "M+": this.getMonth() + 1, //月份
            "d+": this.getDate(), //日
            "h+": Math.abs(this.getHours() - 12), //小时
            "H+": this.getHours(), //小时
            "m+": this.getMinutes(), //分
            "s+": this.getSeconds(), //秒
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度
            "fff": this.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : ((RegExp.$1.length == 2) ? (("00" + o[k]).substr(("" + o[k]).length)) : (("000" + o[k]).substr(("" + o[k]).length))));
            }
        }
    } catch (e) {
        fmt = value;
    }
    return fmt;
}
/**
 *js中更改日期
 * y年， m月， d日， h小时， n分钟，s秒
 */
Date.prototype.Add = function (part, value) {
    value *= 1;
    if (isNaN(value)) {
        value = 0;
    }
    switch (part) {
        case "y":
            this.setFullYear(this.getFullYear() + value);
            break;
        case "m":
            this.setMonth(this.getMonth() + value);
            break;
        case "d":
            this.setDate(this.getDate() + value);
            break;
        case "h":
            this.setHours(this.getHours() + value);
            break;
        case "n":
            this.setMinutes(this.getMinutes() + value);
            break;
        case "s":
            this.setSeconds(this.getSeconds() + value);
            break;
        default:
    }
    return this;
}
// 字符时间转换date
String.prototype.tryDate = function () {
    // 兼容ios毫秒
    let value = this.replace(/-/g, '/');
    let arr = value.split('.');
    value = new Date(arr[0]);
    if (arr[1]) {
        value.setMilliseconds(arr[1]);
    }
    return value;
}