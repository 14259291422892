import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
const routers = [
    {
        path: '',
        name: 'bae',
        component: () => import('../views/Home.vue'),
        redirect: '/login',
        meta: {
            title: '德贝纳'
        }
    },
    {
        path: '/',
        name: 'root',
        component: () => import('../views/Home.vue'),
        meta: {
            title: '德贝纳'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {
            login: false,
            title: '登录'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
            title: '德贝纳'
        }
    },
]
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: routers
})


export default router;