import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import router from './router/index.v3'

import '../public/weui/weui.css';
import './css/style.css'
import './common/datetime';

Vue.config.productionTip = false

import dbn from './common/dbn.store';
window.$dbn = dbn;
window.dbn = dbn;

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app');