export default {
    ClearUser() {
        this.removeStorageSync('user')
        console.log('清除用户');
    },
    SetUser(user) {
        console.log('更新用户');
        return this.setStorageSync('user', user);
    },
    GetUser() {
        const user = this.getStorageSync('user');
        if (user) {
            return user;
        }
    },
    setStorageSync(key, value) {
        if (typeof (value) === 'object') {
            value = JSON.stringify(value)
        }
        window.localStorage.setItem(key, value);
        return true;
    },
    getStorageSync(key) {
        const val = window.localStorage.getItem(key);
        if (val) {
            return JSON.parse(val)
        }
        return false;
    },
    removeStorageSync(key) {
        window.localStorage.removeItem(key);
    }
}
